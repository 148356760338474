import apiService from "@/http/apiService";

const authentication = {
  namespaced: true,

  state: {
    login: {},
    permissions: [],
    userRole: "",
    token: "",
    roles: [],
    users: [],
  },

  mutations: {
    setToken(state, data) {
      state.token = data;
    },
    // setUser
    setUser(state, data) {
      state.users = data;
    },

    // setUserRole
    setUserRole(state, data) {
      state.userRole = data;
    },

    // setUserRoles
    setUserRoles(state, data) {
      state.roles = data;
    },
    // setLogin
    setLogin(state, data) {
      state.login = data;
    },

    // setUserPermissions
    setUserPermissions(state, data) {
      state.permissions = data;
    },

    resetState(state) {
      state.token = "";
      state.userRole = "";
      state.login = {};
      state.permissions = [];
      state.roles = [];
      state.users = [];
    },
  },

  actions: {
    // logIn
    async logIn({ commit, dispatch }, data) {
      try {
        const response = await apiService.logIn(data);
        const token = response.data.token;
        localStorage.setItem("token", token);

        commit("setToken", token);
        commit("setLogin", response.data);
        commit("setUserRole", response.data.roles[0]);

        await dispatch("getUserPermissions");
        return response;
      } catch (error) {
        console.log("setLogin", error);
      }
    },

    // logOut
    async logOut({ commit }) {
      commit("resetState");

      // Reset state for other modules
      commit("claims/resetState", null, { root: true });
      commit("shared/resetState", null, { root: true });
      //commit("sideBar/resetState", null, { root: true });
      commit("workflow/resetState", null, { root: true });
    },

    // getUserPermissions
    async getUserPermissions({ commit }) {
      try {
        const response = await apiService.getUserPermissions();

        commit("setUserPermissions", response.data);
        return response;
      } catch (error) {
        console.log("getUserPermissions", error);
      }
    },

    async fetchUserRoles({ commit }, data) {
      try {
        const response = await apiService.getUserRoles(data);
        commit("setUserRoles", response.data);
        return response;
      } catch (error) {
        console.log("fetchUserRoles", error);
      }
    },

    async createUser({ commit }, data) {
      try {
        const response = await apiService.createUser(data);
        commit("setUser", response.data);
        return response;
      } catch (error) {
        console.log("createUser", error);
      }
    },
    async createRole(_, data) {
      try {
        const response = await apiService.createRole(data);

        return response;
      } catch (error) {
        console.log("createRole", error);
      }
    },
    async createPermission(_, data) {
      try {
        const response = await apiService.createPermission(data);
        return response;
      } catch (error) {
        console.log("createPermission", error);
      }
    },
    async createPermissionRole(_, data) {
      try {
        const response = await apiService.createPermissionRole(data);
        return response;
      } catch (error) {
        console.log("createPermissionRole", error);
      }
    },
  },

  getters: {
    getUserRoles(state) {
      return state.roles;
    },
    getUserDetails(state) {
      return state.login;
    },

    // getUserRole
    getUserRole(state) {
      return state.userRole;
    },

    // getUserPermissions
    getUserPermissions(state) {
      return state.permissions;
    },
  },
};

export default authentication;
