<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),

  async created() {
    // await this.$store.dispatch("claims/fetchUsers");
    await this.$store.dispatch("sideBar/getSideBarManuClaims");
    await this.$store.dispatch("workflow/getWorkflows");
    await this.$store.dispatch("authentication/logIn");
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
//
</style>
