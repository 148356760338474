import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// sidebar
import sideBar from "./modules/sideBar/sideBar.js";
import claims from "./modules/claims/claims.js";
import shared from "./modules/shared/shared.js";
import workflow from "./modules/workflow/workflow.js";
import authentication from "./modules/authentication/authentication.js";

// FAO

// Supervisor

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  getters: {},

  mutations: {},

  actions: {},

  modules: {
    // sidebar
    sideBar,

    // FAO
    claims,

    // shared
    shared,

    // workflow
    workflow,

    // authentication
    authentication,
  },

  plugins: [createPersistedState()],
});
